import { TodoItem } from './types';
import { ActionsUnion, createAction } from 'utils/action-utils';

export enum TodoActionTypes {
    ADD_TODO = '[TODO] ADD_TODO',
    MARK_TODO_COMPLETE = '[TODO] MARK_TODO_COMPLETE',
    MARK_TODO_INCOMPLETE = '[TODO] MARK_TODO_INCOMPLETE'
}

export const TodoActions = {
    addTodo: (todo: TodoItem) => (
        createAction(TodoActionTypes.ADD_TODO, todo)
    ),
    markTodoComplete: (id: TodoItem['id']) => (
        createAction(TodoActionTypes.MARK_TODO_COMPLETE, id)
    ),
    markTodoIncomplete: (id: TodoItem['id']) => (
        createAction(TodoActionTypes.MARK_TODO_INCOMPLETE, id)
    )
}

export type TodoActions = ActionsUnion<typeof TodoActions>;

