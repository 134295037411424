import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';
import { TodoItem } from 'store/todos/types';
import { TodoActions } from 'store/todos/actions';

const useStyles = (props: Props) => makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: theme.typography.pxToRem(400),

        backgroundColor: props.todo.completed ? '#95ff956b' : 'inherit',
        border: '1px solid #d5d5d5',
        borderRadius: 4,
        padding: theme.spacing(0.75, 2),
        paddingRight: theme.spacing(0.5),
        margin: theme.spacing(1, 0)
    },
    text: {
        textDecoration: props.todo.completed ? 'line-through' : 'inherit'
    }
}));

interface Props {
    todo: TodoItem;
}

export default function TodoRow(props: Props) {
    const { todo } = props;
    const { id, name, completed, priority } = todo;
    const classes = useStyles(props)();
    const dispatch = useDispatch();

    const toggleCompleted = () => {
        if (completed) {
            dispatch(TodoActions.markTodoIncomplete(id));
        }
        else {
            dispatch(TodoActions.markTodoComplete(id));
        }
    }

    return (
        <div className={classes.root}>
            <div>
                {`[${priority}] `}
                <div className={classes.text}>
                    {name}
                </div>
            </div>
            <Button variant="outlined" onClick={toggleCompleted}>
                {`Mark ${completed ? 'not done' : 'done'}`}
            </Button>
        </div>
    )
}
