import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { Switch, Route, Redirect } from 'react-router-dom';
import routes from 'config/routes';
import ViewAllTodosPage from 'pages/ViewAllTodos';
import { useDispatch } from 'react-redux';
import { GlobalActions } from 'store/global/actions';
import AddTodoPage from 'pages/AddTodo';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        minHeight: '100%',
        backgroundColor: '#b3e3ff42',
        padding: theme.spacing(4)
    },
    app: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '0 auto'
    }
}));

export default function AuthedLayout() {
    const classes = useStyles();
    const dispatch = useDispatch();
    dispatch(GlobalActions.initialiseWebSocket());

    return (
        <div className={classes.root}>
            <div className={classes.app}>
                <Switch>
                    <Route exact path={routes.AUTHED.VIEW_ALL_TODOS.route}>
                        <ViewAllTodosPage />
                    </Route>
                    <Route path={routes.AUTHED.ADD_TODO.route}>
                        <AddTodoPage />
                    </Route>
                    <Redirect to={routes.AUTHED.VIEW_ALL_TODOS.route} />
                </Switch>
            </div>
        </div>
    );
}
