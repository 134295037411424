
export default {
    PUBLIC: {

    },
    AUTHED: {
        VIEW_ALL_TODOS: {
            route: '/todo/all',
            url: () => '/todo/all'
        },
        ADD_TODO: {
            route: '/todo/add',
            url: () => '/todo/add'
        }
    }
}
