import { GlobalActions, GlobalActionTypes } from './actions';
import { initialStateGlobal } from './intial-state';
import { GlobalState } from './types';

export const GlobalStateReducer = (state = initialStateGlobal, action: GlobalActions): GlobalState => {
    switch (action.type) {
        case GlobalActionTypes.INITIALISE_WEB_SOCKET: {
            return {
                ...state
            }
        }
        case GlobalActionTypes.INITIALISE_WEBSOCKET_DONE: {
            return {
                ...state
            }
        }
        default: {
            return state;
        }
    }
}
