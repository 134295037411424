import { eventChannel } from 'redux-saga';
import { call, fork, put, take, takeLatest } from 'redux-saga/effects';
import { GlobalActions, GlobalActionTypes } from './actions';

function tempCreateChan(sock: WebSocket) {
    return eventChannel((emit) => {

        if (!!sock && !!emit && !!sock.onmessage) {
            // @ts-ignore
            sock.onmessage((msg) => emit(msg.data));
        }

        return () => {
            sock.close();
        }
    });
}

function* initialiseWebSocketAsync(action: ReturnType<typeof GlobalActions.initialiseWebSocket>) {
    const socket = new WebSocket(`ws://${process.env.REACT_APP_SERVER_BASE_URL}`);
    console.log('connecting to', `ws://${process.env.REACT_APP_SERVER_BASE_URL}`)
    const channel = yield call(tempCreateChan, socket);

    while (true) {
        const { message } = yield take(channel);
        const type: GlobalActionTypes = message.type;
        console.log('ws init saga', message, type)
        yield put(GlobalActions.initialiseWebSocketDone());
    }
}

function* watchInitialiseWebSocket() {
    yield takeLatest(
        GlobalActionTypes.INITIALISE_WEB_SOCKET,
        initialiseWebSocketAsync
    );
}

export default [
    fork(watchInitialiseWebSocket)
];
