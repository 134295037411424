import { createStore, compose, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { rootReducer, RootState } from './root-reducer';
import rootSaga from './root-saga';

const sagaMiddleware = createSagaMiddleware();

// export default function configureStore(initialState: RootState) {
//     // @ts-ignore
//     if (module.hot) {
//         // @ts-ignore
//         module.hot.accept('./root-reducer', () => {
//             const nextReducer = require('./root-reducer').default;
//             store.replaceReducer(nextReducer);
//         });
//     }

//     return store;
// }

const storeEnhancer = process.env.NODE_ENV === 'production'
    ? compose(
        applyMiddleware(sagaMiddleware)
    )
    : composeWithDevTools(
        applyMiddleware(sagaMiddleware)
    );

export const store = createStore(rootReducer, storeEnhancer);
sagaMiddleware.run(rootSaga);
