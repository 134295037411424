import { TodoActions, TodoActionTypes } from './actions';
import { intialStateTodos } from './initial-state';
import { TodoItem, Todos } from './types';

export const TodosReducer = (todos = intialStateTodos, action: TodoActions): Todos => {  
    switch (action.type) {
        case TodoActionTypes.ADD_TODO: {
            return [ ...todos, action.payload ]
        }
        case TodoActionTypes.MARK_TODO_COMPLETE: {
            const updatedTodos: TodoItem[] = todos.map((t: TodoItem) => t.id === action.payload ? { ...t, completed: true } : t);
            return updatedTodos;
        }
        case TodoActionTypes.MARK_TODO_INCOMPLETE: {
            const updatedTodos: TodoItem[] = todos.map((t: TodoItem) => t.id === action.payload ? { ...t, completed: false } : t);
            return updatedTodos;
        }
        default: {
            return todos;
        }
    }
}
