import { combineReducers } from 'redux';
import { GlobalStateReducer } from './global/reducer';
import { GlobalState } from './global/types';
import { TodosReducer } from './todos/reducer';
import { Todos } from './todos/types';

export interface RootState {
    globalState: GlobalState;
    todos: Todos;
}

export const rootReducer = combineReducers<RootState>({
    globalState: GlobalStateReducer,
    todos: TodosReducer
});
