import React from 'react';
import AppLayout from 'layouts/App';
import AuthedLayout from 'layouts/Authed';
import PublicLayout from 'layouts/Public';

function App() {
    const authed: boolean = true;

    return (
        <AppLayout>
            { authed ? <AuthedLayout /> : <PublicLayout />}
        </AppLayout>
    );
}

export default App;
