import React from 'react';

interface Props {
    children: React.ReactNode;
}

export default function AppLayout(props: Props) {
    const { children } = props;

    return (
        <React.Fragment>
            { children }
        </React.Fragment>
    );
}
