import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    root: {}
}));

export default function PublicLayout() {
    const classes = useStyles();
    
    return (
        <div className={classes.root}>
            not authed
        </div>
    );
}
