import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useDispatch } from 'react-redux';
import { TodoActions } from 'store/todos/actions';
import { TodoItem, TodoPriority } from 'store/todos/types';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom'
import routes from 'config/routes';
import { v4 as uuid } from 'uuid';

const useStyles = makeStyles((theme: Theme) => ({
    selectedButton: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',

        '&:hover': {
            backgroundColor: theme.palette.primary.main
        }
    },
    navButton: {
        marginBottom: theme.spacing(4),
        backgroundColor: '#ffdcfc',
        border: '1px solid #ff9dfc'
    },
    submitButton: {
        marginTop: theme.spacing(3)
    }
}));

type TodoItemFormState = Omit<TodoItem, 'id'>;

const TodoSchema = yup.object().shape<Omit<TodoItemFormState, 'id'>>({
    name: yup
        .string()
        .required('Enter the name of the todo item'),
    priority: yup
        .mixed<TodoPriority>()
        .oneOf(Object.values(TodoPriority))
        .required('Pick a priority'),
    completed: yup
        .boolean()
});

export default function AddTodoPage() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        errors
    } = useForm<TodoItemFormState>({
        resolver: yupResolver(TodoSchema)
    });
    const history = useHistory();

    useEffect(() => {
        register('priority');
    });

    const priorityValue = watch('priority');

    const handlePriorityChange = (priority: TodoPriority) => {
        setValue('priority', priority);
    }

    const handleBack = () => {
        history.push(routes.AUTHED.VIEW_ALL_TODOS.url());
    }

    const onSubmit = handleSubmit((data: TodoItemFormState) => {
        dispatch(TodoActions.addTodo({
            id: uuid(),
            ...data
        }));
        handleBack()
    });

    return (
        <div>
            <Button
                variant="outlined"
                classes={{ root: classes.navButton }}
                onClick={handleBack}
            >
                Back to view page
            </Button>
            <form onSubmit={onSubmit}>
                <Grid
                    container
                    spacing={2}
                    justify="center"
                >
                    <Grid item xs={12}>
                        <TextField
                            label="Name"
                            name="name"
                            inputRef={register}
                            error={!!errors.name}
                            helperText={errors.name?.message}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>Priority</Typography>
                        {!!errors.priority && errors.priority.message}
                        <ButtonGroup fullWidth>
                            <Button
                                name="priority-low"
                                onClick={() => handlePriorityChange(TodoPriority.LOW)}
                                className={clsx(priorityValue === TodoPriority.LOW && classes.selectedButton)}
                            >
                                Low
                            </Button>
                            <Button
                                name="priority-med"
                                onClick={() => handlePriorityChange(TodoPriority.MEDIUM)}
                                className={clsx(priorityValue === TodoPriority.MEDIUM && classes.selectedButton)}
                            >
                                Med
                            </Button>
                            <Button
                                name="priority-high"
                                onClick={() => handlePriorityChange(TodoPriority.HIGH)}
                                className={clsx(priorityValue === TodoPriority.HIGH && classes.selectedButton)}
                            >
                                High
                        </Button>
                        </ButtonGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="outlined"
                            type="submit"
                            classes={{ root: classes.submitButton }}
                            fullWidth
                        >
                            Add
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
}
