import { createMuiTheme } from "@material-ui/core/styles";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

declare module "@material-ui/core/styles/createMuiTheme" {
    interface Theme {
        timapp: {

        };
    }
    interface ThemeOptions {
       timapp?: {

       }
    }
}

const theme = createMuiTheme({
   
});

export default theme;
