import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { TodoItem } from 'store/todos/types';
import { RootState } from 'store/root-reducer';
import TodoRow from './components/TodoRow';
import { useHistory } from 'react-router-dom';
import routes from 'config/routes';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    navButton: {
        marginBottom: theme.spacing(4),
        backgroundColor: '#ffdcfc',
        border: '1px solid #ff9dfc'
    }
}))

export default function ViewAllTodosPage() {
    const { todos } = useSelector((state: RootState) => ({
        todos: state.todos
    }), shallowEqual);
    const history = useHistory();
    const classes = useStyles();

    const onNavClick = () => {
        history.push(routes.AUTHED.ADD_TODO.url());
    }

    return (
        <div>
            <Button
                variant="outlined"
                onClick={onNavClick}
                classes={{ root: classes.navButton }}
            >
                Add new todo
            </Button>
            { todos.map((t: TodoItem) => (
                <TodoRow key={t.name} todo={t} />
            ))}
        </div>
    );
}
