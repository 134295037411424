
export enum TodoPriority {
    LOW = 'L',
    MEDIUM = 'M',
    HIGH = 'H'
}

export interface TodoItem {
    id: string;
    name: string;
    priority: TodoPriority;
    completed: boolean;
}

export type Todos = TodoItem[];
