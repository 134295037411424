import { ActionsUnion, createAction } from 'utils/action-utils';

export enum GlobalActionTypes {
    INITIALISE_WEB_SOCKET = '[GLOBAL] INITIALISE_WEBSOCKET',
    INITIALISE_WEBSOCKET_DONE = '[GLOBAL] INITIALISE_WEBSOCKET_DONE',
    INITIAL_STATE_REQUEST = '[GLOBAL] INITIAL_STATE_REQUEST'
}

export const GlobalActions = {
    initialiseWebSocket: () => (
        createAction(GlobalActionTypes.INITIALISE_WEB_SOCKET)
    ),
    initialiseWebSocketDone: () => (
        createAction(GlobalActionTypes.INITIALISE_WEBSOCKET_DONE)
    )
}

export type GlobalActions = ActionsUnion<typeof GlobalActions>;

